import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

export const getMailTransporter = createAsyncThunk('MailTransporterSlice/getMailTransporter', async () => {
    try {
        let response = await axios.get('/mail-transporter', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getMailTransporterOptions = createAsyncThunk('MailTransporterSlice/getMailTransporterOptions', async () => {
    try {
        let response = await axios.get('/mail-transporter?status=1', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        let options = response.data.map((row) => {
            return { value: row.id, label: `${row.name} <${row?.email}>` };
        });
        return Promise.resolve(options);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createMailTransporter = createAsyncThunk('MailTransporterSlice/createMailTransporter', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/mail-transporter', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Template Added successfully.');
        dispatch(getMailTransporter());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateMailTransporter = createAsyncThunk('MailTransporterSlice/updateMailTransporter', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/mail-transporter/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Template updated successfully.');
        dispatch(getMailTransporter());

        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getMailTransporterById = createAsyncThunk('MailTransporterSlice/getMailTransporterById', async (id, { dispatch }) => {
    try {
        let response = await axios.get('/mail-transporter/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteMailTransporter = createAsyncThunk('MailTransporterSlice/deleteMailTransporter', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/mail-transporter/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Mail Template deleted successfully');
        dispatch(getMailTransporter());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const MailTransporterSlice = createSlice({
    name: 'MailTransporterSlice',
    initialState: {
        mailTransporter: [],
        mailTransporterOptions: []
    },
    extraReducers: (builder) => {
        builder.addCase(getMailTransporter.fulfilled, (state, action) => {
            state.mailTransporter = action.payload;
            return;
        });
        builder.addCase(getMailTransporterOptions.fulfilled, (state, action) => {
            state.mailTransporterOptions = action.payload;
            return;
        });
    }
});

export default MailTransporterSlice.reducer;
