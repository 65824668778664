import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// ** Loader Import
import showLoader from '@components/loader';

export const getDashboardStats = createAsyncThunk('DashboardSlice/getDashboardStats', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/dashboard-stats', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const DashboardSlice = createSlice({
    name: 'DashboardSlice',
    initialState: {
        statsCount: { campaignCount: 0, userCount: 0, templateCount: 0, transporterCount: 0 }
    },
    extraReducers: (builder) => {
        builder.addCase(getDashboardStats.fulfilled, (state, action) => {
            state.statsCount = action.payload;
            return;
        });
    }
});

export default DashboardSlice.reducer;
