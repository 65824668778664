// ** Reducers Imports
import navbar from './navbar';
import layout from './layout';
import auth from './authentication';
import permission from './permission';
import crudsAPIs from './crudStore';

const rootReducer = {
    auth,
    navbar,
    layout,
    permission,
    ...crudsAPIs
};

export default rootReducer;
