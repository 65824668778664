import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

export const getMailTemplate = createAsyncThunk('mailTemplateSlice/getMailTemplate', async () => {
    try {
        let response = await axios.get('/mail-template', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getMailTemplateOptions = createAsyncThunk('mailTemplateSlice/getMailTemplateOptions', async () => {
    try {
        let response = await axios.get('/mail-template?status=1', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        let options = response.data.map((row) => {
            return { value: row.id, label: row.name };
        });
        return Promise.resolve(options);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createMailTemplate = createAsyncThunk('mailTemplateSlice/createMailTemplate', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/mail-template', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Template Added successfully.');
        dispatch(getMailTemplate());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateMailTemplate = createAsyncThunk('mailTemplateSlice/updateMailTemplate', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/mail-template/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Template updated successfully.');
        dispatch(getMailTemplate());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getMailTemplateById = createAsyncThunk('mailTemplateSlice/getMailTemplateById', async (id, { dispatch }) => {
    try {
        let response = await axios.get('/mail-template/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteMailTemplate = createAsyncThunk('mailTemplateSlice/deleteMailTemplate', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/mail-template/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Mail Template deleted successfully');
        dispatch(getMailTemplate());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const mailTemplateSlice = createSlice({
    name: 'mailTemplateSlice',
    initialState: {
        mailTemplate: [],
        mailTemplateOptions: []
    },
    extraReducers: (builder) => {
        builder.addCase(getMailTemplate.fulfilled, (state, action) => {
            state.mailTemplate = action.payload;
            return;
        });
        builder.addCase(getMailTemplateOptions.fulfilled, (state, action) => {
            state.mailTemplateOptions = action.payload;
            return;
        });
    }
});

export default mailTemplateSlice.reducer;
