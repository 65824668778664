import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

export const getUser = createAsyncThunk('userSlice/getUser', async () => {
    try {
        let response = await axios.get('/user', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createUser = createAsyncThunk('userSlice/createUser', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/user', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'User created successfully.');
        dispatch(getUser());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateUser = createAsyncThunk('userSlice/updateUser', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/user/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'User updated successfully.');
        dispatch(getUser());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getUserById = createAsyncThunk('userSlice/getUserById', async (id, include = 0) => {
    try {
        let response = await axios.get('/user/' + id, {
            headers: {
                'Content-Type': 'application/json',
                include: include
            }
        });
        return response?.data;
    } catch (err) {
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return err;
    }
});

export const deleteUser = createAsyncThunk('userSlice/deleteUser', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/user/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'User deleted successfully');
        dispatch(getUser());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
    }
});

export const getRole = createAsyncThunk('userSlice/getRole', async () => {
    try {
        let response = await axios.get('/role?status=1', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        let option = [];
        response?.data?.map((row) => {
            option.push({
                label: row.name,
                value: row.id
            });
        });
        return option;
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('userSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/user/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getUser());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.err(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const userSlice = createSlice({
    name: 'userSlice',
    initialState: {
        user: []
    },
    extraReducers: (builder) => {
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.user = action.payload;
            return;
        });
    }
});

export default userSlice.reducer;
