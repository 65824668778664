import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

export const getMailLogs = createAsyncThunk('MailLogsSlice/getMailLogs', async (data = null) => {
    try {
        let response = await axios.get(`/mail-logs?${queryString.stringify(data)}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const MailLogsSlice = createSlice({
    name: 'MailLogsSlice',
    initialState: {
        mailLogs: []
    },
    extraReducers: (builder) => {
        builder.addCase(getMailLogs.fulfilled, (state, action) => {
            state.mailLogs = action.payload;
            return;
        });
    }
});

export default MailLogsSlice.reducer;
