import mailTemplate from '../views/MailSettings/MailTemplate/store';
import mailTransporter from '../views/MailSettings/MailTransporter/store';
import mailLogs from '../views/MailSettings/MailLogs/store';
import roleSlice from '../views/Setup/RoleRights/Role/store';
import userSlice from '../views/Setup/User/store';
import dasboard from '../views/Dashboard/store';

const crudsApi = {
    roleSlice,
    userSlice,
    mailTemplate,
    mailTransporter,
    mailLogs,
    dasboard
};
export default crudsApi;
