import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

export const getRole = createAsyncThunk('roleSlice/getRole', async () => {
    try {
        let response = await axios.get('/role', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createRole = createAsyncThunk('roleSlice/createRole', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/role', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Role added successfully.');
        dispatch(getRole());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateRole = createAsyncThunk('roleSlice/updateRole', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/role/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Role updated succesfully.');
        dispatch(getRole());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteRole = createAsyncThunk('roleSlice/deleteRole', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/role/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Role deleted successfully.');
        dispatch(getRole());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('roleSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/role/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getRole());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const roleSlice = createSlice({
    name: 'roleSlice',
    initialState: {
        role: []
    },
    extraReducers: (builder) => {
        builder.addCase(getRole.fulfilled, (state, action) => {
            state.role = action?.payload;
            return;
        });
    }
});

export default roleSlice.reducer;
